@use "sass:map";

@use "pico";
@import "colors";
@import "../loveVanilla/style.scss";

@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("../../node_modules/easymde/dist/easymde.min.css");

root {
  // --pico-font-family: Pacifico, cursive;
  --pico-border-radius: 0.5rem;
  --pico-typography-spacing-vertical: 1.5rem;
  --pico-form-element-spacing-vertical: 1rem;
  --pico-form-element-spacing-horizontal: 1.25rem;
  --spacing-company: 3rem;
  --font-weight: 400;
  --border-radius: 0;
}

$title-main-color: #3f9afc;

h1 {
  --pico-font-weight: 400;
  --pico-typography-spacing-vertical: 0.5rem;
  margin: 0; /* Remove default margin */
}

h2 {
  --pico-font-weight: 700;
  --pico-typography-spacing-vertical: 0.5rem;
}

article h2.rank {
  font-size: 1.5rem;
  --pico-font-weight: 700;
  --pico-typography-spacing-vertical: 0.5rem;
}

article h1 {
  font-size: medium;
  --pico-font-weight: 700;
  --pico-typography-spacing-vertical: 0.5rem;
}

article h2 {
  font-size: medium;
  --pico-font-weight: 700;
  --pico-typography-spacing-vertical: 0.5rem;
}

.paypal-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5em 0.9em;
  background-color: #0070ba;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  font-size: smaller;
  width: 8em;
}

.paypal-button:hover {
  background-color: #005e94;
}

.paypal-icon {
  width: 1.8em; /* Adjust size as needed */
  height: auto;
  margin-right: 0.5em; /* Space between icon and text */
  margin-left: 0em;
}

footer {
  font-size: small;
  font-style: italic;
}

article.club-info {
  header {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  header {
    display: flex;
    align-items: center;
    gap: 1rem;
    vertical-align: bottom;
  }

  header h5 {
    margin: auto 0;
  }

  header img {
    width: 3.8em;
    height: auto;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    padding: 0.01em;
    border: #e5e5e5 solid 3px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3),
      0 1px 3px rgba(255, 255, 255, 0.2);
  }

  .item-details {
    margin-bottom: 10px; /* Adds spacing between items */
  }

  .search-title {
    display: block; /* Ensures the title is on its own line */
    font-size: 1.2em; /* Makes the title larger */
    font-weight: bold; /* Emphasizes the title */
    color: $title-main-color; /* Optional: changes the link color */
    text-decoration: none; /* Removes underline from the link */
  }

  .search-title:hover {
    text-decoration: underline; /* Adds underline on hover for accessibility */
  }

  .rank {
    display: block; /* Places the rank on its own line */
    font-size: 0.9em; /* Makes the rank text slightly smaller */
    color: #6c757d; /* Optional: gives a muted color */
  }
}

/* Full-page loader */
#loading-screen {
  position: fixed;
  /* Ensure it covers the whole viewport */
  top: 0;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(29, 98, 209, 1);
  /* White background with slight transparency */
  display: flex;
  /* Flexbox to center content */
  align-items: center;
  justify-content: center;
  /* Place above all other content */
}

.hidden {
  animation: fadeOut 0.5s forwards;
  z-index: -1;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

span.club-logo-frame {
  display: flex; /* Flexbox to center the logo */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  width: 5em; /* Equal width and height for round shape */
  height: 5em;
  border: 1px solid #000; /* Frame border */
  border-radius: 50%; /* Makes the frame circular */
  overflow: hidden; /* Ensures the logo doesn't overflow */
}

.site-title a {
  font-size: larger;
  font-weight: 700;
  color: #f8f9fa; /* Light text color */
  // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7), 0 0 10px rgba(255, 255, 255, 0.5);
  font-family: "Poppins", sans-serif; /* Modern and clean font */
  letter-spacing: 0.05em; /* Adds slight spacing between letters */
  text-decoration: none;
  color: inherit;
  margin-left: 0.3em;
}

.site-title:hover a {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7), 0 0 10px rgba(255, 255, 255, 0.5);
}

.page-badge {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  border-radius: 12px;
  text-decoration: none;
  font-size: 14px;
  font-stretch: semi-condensed;
}

.page-badge svg {
  margin-right: 5px;
  height: 18px;
  width: 18px;
}

.page-badge:hover {
  background-color: #24292e;
}

.player-name {
  font-size: 1.5rem; /* 24px */
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
}

.player-born {
  font-size: 0.875rem; /* 14px */
  color: #aaa;
}

.player-number {
  font-size: 1.125rem; /* 18px */
  font-weight: bold;
  color: #f39c12;
}

.profile-ownership {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
}

.ownership-status {
  font-size: 1em;
  color: #333;
}

#ownership-label {
  font-weight: bold;
  color: #007bff; /* System color, change dynamically */
}

.ownership-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

.ownership-button:hover {
  background-color: #0056b3;
}

a.coach-search-google,
a.player-search-google {
  margin-right: 0.5em;
  text-decoration: none;
  text-decoration-skip-ink: none;
  color: #e5e5e5;
}

a.coach-page,
a.player-page {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #e5e5e5;
}

footer ul {
  > li {
    display: inline;
    margin-right: 1em !important;
  }
}

ul#login-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;

  > a {
    text-decoration: none;
    color: #e5e5e5;
  }

  > a:hover {
    text-decoration: underline;
  }
}

.artifact-container {
  .artifact-header-wrapper {
    .artifact-header {
      .artifact-info {
        .club-logo {
          img {
            min-width: 100px;
            max-width: 100px;
            height: auto;
            border-radius: 50%;
            aspect-ratio: 1 / 1;
            padding: 0.01em;
            border: #e5e5e5 solid 3px;
            box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3),
              0 1px 3px rgba(255, 255, 255, 0.2);
          }
        }

        .artifact-key {
          margin: 0 1em 0 1em;

          .artifact-name {
            display: block; /* Ensures the title is on its own line */
            font-size: 1.2em; /* Makes the title larger */
            font-weight: bold; /* Emphasizes the title */
            color: $title-main-color; /* Optional: changes the link color */
            text-decoration: none; /* Removes underline from the link */
            text-transform: capitalize;
            margin-bottom: 0;
          }

          .artifact-sub-name {
            display: flex; /* Places the rank on its own line */
            flex-direction: flex-start;
            gap: 0.2em;
            align-items: center;
            font-size: 1.3em; /* Makes the rank text slightly smaller */
            color: #6c757d; /* Optional: gives a muted color */
          }
        }
      }
    }
  }
}

.follow-button {
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: white;
  border: none;
  border-radius: 50px;
  padding: 12px 25px;
  cursor: pointer;
  font-size: small;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.follow-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-45deg);
  transition: left 0.4s ease;
}

.follow-button:hover::before {
  left: 100%;
}

.follow-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.follower-count {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 15px;
  border-radius: 25px;
  text-align: center;
}

.player-content {
  border-radius: 1em;
  display: flex;
  height: 90vh;
  padding: 1px;
  width: 100%;
  width: auto;
}

.player-article {
  width: 100%;
  height: 100%;

  .player-article-editor-parent {
    width: 100%;
    height: 100%;
  }
}

textarea {
  height: 100%;
  width: 100%;
}

.editor-toolbar button.active,
.editor-toolbar button:hover {
  background: #1a1b1b !important;
  border-color: #95a5a6;
}

.editor-toolbar button {
  color: #908f8f;
}

/* Target the scroll bar for the entire document */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f4f4f4; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb (the draggable part) */
  border-radius: 6px; /* Rounded corners for the scrollbar */
  border: 2px solid #f4f4f4; /* Adds space around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

// Define media expressions
$media-expressions: (
  "phone": "(max-width: 640px)",
  "tablet": "(min-width: 641px) and (max-width: 1024px)",
  "desktop": "(min-width: 1024px)",
) !default;

// Mixin to use media expressions
@mixin media($types...) {
  @each $type in $types {
    $query: map-get($media-expressions, $type); // Get the query from the map
    @if $query {
      @media #{$query} {
        @content;
      }
    } @else {
      @error "No media query defined for '#{$type}' in $media-expressions.";
    }
  }
}

// Use the mixin with defined media types
@include media("phone", "tablet") {
  body {
    // background-color: #f4f4f4;
  }

  .artifact-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
    padding: 0.2em;
    // border: 1px dashed #ccc; /* Optional: for visibility */

    .artifact-info {
      display: flex; /* Creates a horizontal layout */
      align-items: center; /* Vertically aligns the logo and text */
      margin-bottom: 10px; /* Adds spacing between items */
    }

    .artifact-menu {
      padding-top: 1em;
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 100%;
    }
  }
}

@include media("desktop") {
  body {
    // background-color: #b51212;
  }
  .artifact-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: auto;
    // border: 1px dashed #ccc; /* Optional: for visibility */
    padding: 0.2em;

    .artifact-info {
      display: flex; /* Creates a horizontal layout */
      align-items: flex-start; /* Vertically aligns the logo and text */
      margin-bottom: 10px; /* Adds spacing between items */
      // max-width: 50vh;
    }

    .artifact-menu {
      padding-top: 1em;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}

.table-col-player-name {
  width: 85%;
}
